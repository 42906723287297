@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  /* montserrat-regular - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;

    src: local(""), /* IE6-IE8 */ url("./assets/fonts/montserrat-v21-latin-regular.woff2") format("woff2"),
      /* Super Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-regular.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-regular.ttf") format("truetype");
  }

  /* montserrat-700 - latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: local(""), /* IE6-IE8 */ url("./assets/fonts/montserrat-v21-latin-700.woff2") format("woff2"),
      /* Super Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-700.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-700.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src: local(""), /* IE6-IE8 */ url("./assets/fonts/montserrat-v21-latin-900.woff2") format("woff2"),
      /* Super Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-900.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-900.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: local(""), /* IE6-IE8 */ url("./assets/fonts/montserrat-v21-latin-100.woff2") format("woff2"),
      /* Super Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-100.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-100.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 200;
    src: local(""), /* IE6-IE8 */ url("./assets/fonts/montserrat-v21-latin-200.woff2") format("woff2"),
      /* Super Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-200.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-200.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: local(""), /* IE6-IE8 */ url("./assets/fonts/montserrat-v21-latin-300.woff2") format("woff2"),
      /* Super Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-300.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/montserrat-v21-latin-300.ttf") format("truetype");
    font-display: swap;
  }
}
