.react-chatbot-kit-chat-btn-send {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-chatbot-kit-chat-container {
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.react-chatbot-kit-chat-bot-message {
  margin-bottom: 10px;
}
.react-chatbot-kit-chat-inner-container {
  max-height: 100%;
  background-color: #fff;
  margin-bottom: 10px;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .react-chatbot-kit-chat-container {
    width: 100%;
    max-height: 100%;
    height: 100%;
  }
  .react-chatbot-kit-chat-inner-container {
    max-height: 100%;
    height: 100%;
  }
  .react-chatbot-kit-chat-message-container {
    overflow-y: scroll;
    margin: 0;
    height: 500px;
  }
  .react-chatbot-kit-chat-bot-message {
    margin-left: 0px;
  }
}
